<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Seleccion de pacientes</p>
    </header>
    <section class="modal-card-body">
      <b-field
          grouped
      >
        <p class="control">
          <button class="button is-success"
                  type="button"
                  @click="onNewPatient">
            Agregar
          </button>
        </p>
        <p class="control">
          <button class="button is-warning"
                  :disabled="!selected"
                  type="button"
                  @click="onEditPatient(selected.code)">
            Modificar
          </button>
        </p>
        <p class="control">
          <button class="button is-info is-tooltip-right tooltip"
                  data-tooltip="Filtrar órdenes"
                  :disabled="!selected"
                  type="button"
                  v-if="canSearchOrders"
                  @click="onSearchOrders(selected.code)">
            <b-icon icon="binoculars"></b-icon>
          </button>
        </p>
        <p class="control">
          <button class="button is-info is-tooltip-right tooltip"
                  data-tooltip="Filtrar preórdenes"
                  :disabled="!selected"
                  type="button"
                  v-if="canSearchOrders && $alfalab.enableForeignWorkUI($auth.user())"
                  @click="onSearchPreOrders(selected.code)">
            Buscar Pre Ordenes
          </button>
        </p>
      </b-field>
      <b-table
          ref="patients_table"
          @keyup.enter.native="onKeyPressEnter"
          @keyup.37.native="onKeyPressLeft"
          @keyup.39.native="onKeyPressRight"
          :data="customData"
          :columns="columns"
          :selected.sync="selected"
          :paginated="paginated"
          :per-page="perPage"
          focusable
          :current-page="currentPage"
          pagination-simple
          narrowed
          @dblclick="onSelectPatient">
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="handleClose">Cerrar</button>
      <button class="button is-primary" type="button" @click="onSelectPatient(selected)">Seleccionar</button>
    </footer>
    <b-modal :active.sync="createModalActive"
             trap-focus
             scroll="keep"
             :destroy-on-hide="false"
             aria-role="dialog"
             width="auto"
             aria-modal>
      <form-modal-patient

          title="Crear Paciente"
          action-text="Guardar"
          action-event="clickCreate"
          @clickCreate="onStoreData"
          :errors="errors"
          :data="dataPatient"
      ></form-modal-patient>
    </b-modal>
  </div>
</template>

<script>
import FormModalPatient from "./FormModalPatient";
import ModalEditPatient from "./ModalEditPatient";
import ModalSelectOrder from "@/components/order/ModalSelectOrder";
import ModalSelectPreOrder from "@/components/order/ModalSelectPreOrder";

export default {
  name: "ModalSelectPatient",
  components: {FormModalPatient},
  props: {
    data: Array,
    params: Object,
    paginated: Boolean,
    hasCompany: Boolean,
    perPage: {
      type: Number,
      default: 15
    },
    canSearchOrders: {
      type: Boolean,
      default: true
    },
    type: String
  },
  data() {
    return {
      currentPage: 1,
      dataPatient: {
        birthday: new Date()
      },
      columns: [
        {
          field: 'lapifan',
          label: '',
          tdAttrs: (row, column) => {
            if (row.lapifan_active.status) {
              return {
                class: 'has-lapifan'
              }
            }
          }
        },
        {
          field: 'code',
          label: 'Código',
          searchable: true,
          width: '120px'
        },
        {
          field: 'name',
          label: 'Nombre',
          searchable: true,
        },
        {
          field: 'last_name',
          label: 'Apellido Paterno',
          searchable: true,
        },
        {
          field: 'other_name',
          label: 'Apellido Materno',
          searchable: true,
        },
        {
          field: 'birthday',
          label: 'Fecha de Nacimiento',
          searchable: true,
          width: '140px'
        },
        {
          field: 'phones',
          label: 'Telefonos',
          searchable: true,
          width: '140px'
        },
        {
          field: 'gender_str',
          label: 'Género'
        },
        {
          field: 'email',
          label: 'Correo electrónico'
        }
      ],
      selected: null,
      editModalActive: false,
      createModalActive: false,
      apiUrl: 'patients',
      errors: {},
      listener: null
    }
  },
  computed: {
    customData() {
      return this.data.map(item => {
        item.phones = `${(item.phone ? 'C: ' + item.phone : '')} ${item.cell_phone ? 'M: ' + item.cell_phone : ''}`
        return item;
      })
    }
  },
  mounted() {
    this.dataPatient = {
      ...this.params
    };
    if (this.data.length) {
      this.selected = this.data[0];
      setTimeout(() => {
        this.$refs.patients_table.focus();
      }, 100);
    }
    if (this.hasCompany) {
      this.columns.push({
        field: 'job_title',
        label: 'Puesto',
        searchable: true,
      });
      this.columns.push({
        field: 'company',
        label: 'Empresa',
        searchable: true,
      });
    }
  },
  methods: {
    handleClose() {
      if (this.type !== 'salescallcenter') {
        this.$store.dispatch('setShowModalSelectPatient', false);
      } else {
        this.$store.dispatch('salescallcenter/setShowModalSelectPatient', false);
      }
    },
    onSelectPatient(data) {
      this.$store.dispatch('setShowModalSelectPatient', true);
      this.$emit('select', data)
    },
    async onStoreData() {

      const {name, last_name, other_name, gender, birthday} = this.dataPatient;

      const total = await this.$http.get('/patients?count=1', {
        params: {
          name,
          last_name,
          other_name,
          gender,
          birthday
        }
      }).then(({data}) => {
        return Promise.resolve(data.total);
      })
      //console.log('total', total);
      if (total && this.$alfalab.enableProtectionPatientsRepeat(this)) {
        this.$buefy.dialog.prompt({
          title: 'Paciente ya existente',
          message: 'Ya existen pacientes con estos datos, favor de verificar y seleccionar un paciente existente o en caso contrario ingrese su contraseña para crear un nuevo paciente.',
          cancelText: 'No, cancelar',
          confirmText: 'Si, quiero crear un paciente',
          closeOnConfirm: false,
          onConfirm: (value, {close}) => {
            this.$loader.show();
            this.$http.post('/auth/check-password', {password: value, context: 'store-patient'}).then(({data}) => {
              if (data.status) {
                this.runCreatePatient(true);
                close();
              } else {
                this.$buefy.dialog.alert({
                  title: 'Contraseña incorrecta',
                  message: 'La contraseña proporcionada es inválida'
                });
              }
            }).catch(errors => {
              this.$alfalab.showError(errors);
            }).finally(() => {
              this.$loader.hide();
            })
          }
        })
      } else {
        const isCompleteInfo = this.checkInfo();
        if (isCompleteInfo) {
          this.runCreatePatient();
        }
      }
    },
    checkInfo() {
      let result = true;
      const params = {...this.dataPatient};
      const validEmail = !!params.email_ignore || (!!!params.email_ignore && !!params.email)
      const validWhatsapp = !!params.cell_phone_ignore || (!!!params.cell_phone_ignore && !!params.cell_phone)
      if (!validEmail || !validWhatsapp) {
        result = false;
        this.$buefy.dialog.alert({
          title: 'Información requerida',
          message: 'No olvides actualizar el número de teléfono móvil para el WhatsApp en el campo teléfono móvil y el correo electrónico en el campo correo.'
        });
      }
      return result;
    },
    runStorePatient(confirmRepeat = false) {
      this.$buefy.dialog.confirm({
        title: 'Crear paciente',
        message: '¿Estas seguro de crear el paciente?',
        confirmText: 'Si, crear',
        cancelText: 'No, cancelar',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          const isEmailValid = await this.$alfalab.onValidateEmail(this, this.dataPatient.email);
          if (isEmailValid.status) {
            this.runCreatePatient();
          } else {
            this.$buefy.dialog.alert({
              title: 'Hay un detalle con el correo proporcionado',
              message: isEmailValid.message,
              confirmText: isEmailValid.allow_continue ? 'Es correcto continuar' : 'Aceptar',
              canCancel: isEmailValid.allow_continue,
              cancelText: 'Cancelar',
              onConfirm: isEmailValid.allow_continue ? this.runCreatePatient : (confirm) => {
              }
            });
          }
        }
      })
    },
    async runCreatePatient(confirmRepeat = false) {
      this.$loader.show();
      const params = {...this.dataPatient};
      if (confirmRepeat) {
        params['force_store_same_patient_data'] = true
      }
      await this.storeData(params)
          .then(data => {
            this.$loader.hide();
            this.$toast.success(data.message);
            this.data.push(data.data);
            this.selected = data.data;
            this.createModalActive = false;
          }, data => {
            this.$loader.hide();
            this.$toast.error(data.message);
          });
    },
    onKeyPressEnter() {
      this.onSelectPatient(this.selected);
    },
    onEditPatient(patient) {
      let vm = this;

      this.$buefy.modal.open({
        parent: this,
        component: ModalEditPatient,
        hasModalCard: true,
        trapFocus: true,
        props: {
          patient,
        },
        events: {
          updatePatient(data) {
            vm.$emit('update:data', data);
            let element = vm.data.find(el => el.id === data.id)
            if (element) {
              element = data;
            }
          }
        }
      });
    },
    onKeyPressLeft() {
      if (this.data.length > this.perPage) {
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      }
    },
    onKeyPressRight() {
      if (this.data.length > this.perPage) {
        let pages = Math.ceil(this.data.length / this.perPage);
        if (this.currentPage < pages) {
          this.currentPage += 1;
        }
      }
    },
    async onSearchOrders(patient) {
      this.$loader.show();
      const vm = this;
      await this.$http.get('orders', {
        params: {
          patient,
          with: 'studies',
          perPage: 200
        }
      }).then(({data}) => {
        this.$buefy.modal.open({
          parent: this,
          component: ModalSelectOrder,
          hasModalCard: true,
          customClass: 'custom-class custom-class-2',
          trapFocus: true,
          events: {
            selectOrder(data) {
              vm.$emit('selectOrder', data);
              if (this) {
                this.$store.dispatch('setShowModalSelectPatient', true);
              }
            },
          },
          props: {
            data: data.data
          }
        })
      }, ({response}) => {

      });
      this.$loader.hide();
    },
    async onSearchPreOrders(patient) {
      this.$loader.show();
      const vm = this;
      await this.$http.get('folio/quotation', {
        params: {
          patient,
          with: 'studies',
          perPage: 200
        }
      }).then(({data}) => {
        this.$buefy.modal.open({
          parent: this,
          component: ModalSelectPreOrder,
          hasModalCard: true,
          customClass: 'custom-class custom-class-2',
          trapFocus: true,
          events: {
            selectOrder(data) {
              let result = Object.assign({}, data, {preorder: data.quotation_id});
              if (data.order) {
                result = Object.assign({}, data, data.order, {preorder: data.quotation_id})
              }
              vm.$emit('selectOrder', result);
              if (this) {
                this.$store.dispatch('setShowModalSelectPatient', true);
              }
            },
          },
          props: {
            data: data.data
          }
        })
      }, ({response}) => {

      });
      this.$loader.hide();
    },
    onNewPatient() {
      this.createModalActive = true;
      this.dataPatient = Object.assign({}, {
        last_name: this.dataPatient.last_name,
        name: this.dataPatient.name,
        other_name: this.dataPatient.other_name,
        birthday: this.dataPatient.birthday,
        company: this.dataPatient.company,
        gender: this.dataPatient.gender
      });
    }
  }
}
</script>

<style scoped>

</style>
